<template>
	<div class="order_wrap fixed_wrap customer_area">
		<div
			class="txt_box_type02"
			v-if="
				this.status === '106' ||
					this.status === '108' ||
					this.status === '109' ||
					this.status === '111'
			"
		>
			<h1 class="tit_page">
				고객님의 주문이<br />
				접수되었습니다.
			</h1>
			<div class="txt_body1">
				제품 주문에 궁금하신 사항이나, 주문 진행사항이<br />궁금하시다면,
				판매인에게 문의해 주세요.<br />
				감사합니다.
			</div>
		</div>
		<div class="message_box mt-3" v-else-if="this.status === '201'">
			<span class="txt_body1" v-html="content"></span>
		</div>
		<div class="txt_box_type02" v-else>
			<h1 class="tit_page">
				정보 입력 기간이<br />
				만료되었습니다.
			</h1>
			<div class="txt_body1">
				주문이 정상 처리되었거나, 주문이 취소되어<br />정보를 찾을 수가
				없습니다.<br />궁금하신 사항은 담당 판매인에게 문의해 주세요.<br />
				감사합니다.
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PayUnComplete',
	data() {
		return {
			dialog: false,
			status: '',
			message: ''
		}
	},
	components: {},
	mounted() {
		this.status = this.$route.params.status
		this.message = this.$route.params.message
	},
	computed: {
		content: function() {
			return this.message.split('\n').join('<br />')
		}
	}
}
</script>
